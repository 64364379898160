import React, { ReactNode } from 'react';
import styled from 'styled-components';
import HyroxLogo from 'app/ui/components/atoms/hyrox-logo';
import PoweredByFiit from 'app/ui/components/atoms/powered-by-fiit';

import LanguagePicker from 'app/ui/components/molecules/language-picker/wrapper';

import useConfig from 'app/config';

type Props = {
  children: ReactNode;
  $showBrandImage?: Boolean;
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  ${({ theme }) => `
    background: ${theme.colors.black};
    color: ${theme.colors.white};
  `}
`;

const BrandImage = styled.div`
  display: none;

  ${({ theme: { media } }) => {
    return `
      ${media.large(
        () => `
          display: flex;
          width: 60%;
          overflow: hidden;
          background-image: url('https://images.prod.hyrox.fiit-tech.net/j7tWXvRWPKJycgJubSB9Ty.png');
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        `,
      )} 
    `;
  }}
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${({ theme: { media, spacing } }) => {
    return `
    padding: ${spacing.superhumongous} ${spacing.jumbo} ${spacing.superhumongous};
    ${media.medium(
      () => `
        width: 33.75rem;
        margin: 0 auto;
        `,
    )}

    ${media.large(
      () => `
        width: 40%;
        `,
    )}
      
    `;
  }}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 18rem;
  ${({ theme: { media } }) => `
    ${media.medium(
      () => `
        width: 24.375rem;
      `,
    )}
  `}
  ${({ theme: { media } }) => `
    ${media.large(
      () => `
        width: 26.25rem;
      `,
    )}
  `}
`;

const Footer = styled.div`
  ${({ theme: { spacing, colors } }) => `
    background: ${colors.black};
    color: ${colors.white};
    margin: ${spacing.massive} 0 0;
  `}
`;

const Header = styled.div`
  ${({ theme }) => `
    background: ${theme.colors.black};
    color: ${theme.colors.white};
  `}
`;

const HyroxLogoWrapper = styled.div`
  text-align: center;
`;

const LanguagePickerArea = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
`;

const StyledHyroxLogo = styled(HyroxLogo)`
  ${({ theme: { media, spacing } }) => `
    ${media.small(
      () => `
        margin: ${spacing.extreme} 0 ${spacing.massive};
        width: 17rem;
      `,
    )}

    ${media.medium(
      () => `
        margin: ${spacing.jumbo} 0 ${spacing.massive};
        width: 16rem;
      `,
    )}
  `}
`;

const Entry: React.FC<Props> = ({ children, $showBrandImage = true }: Props) => {
  const { config } = useConfig();

  return (
    <Container>
      {$showBrandImage && <BrandImage />}
      <Wrapper>
        {config.REACT_APP_LOCALISATION_ENABLED && (
          <LanguagePickerArea>
            <LanguagePicker />
          </LanguagePickerArea>
        )}
        <Header>
          <HyroxLogoWrapper>
            <StyledHyroxLogo />
          </HyroxLogoWrapper>
        </Header>
        <Content>{children}</Content>
        <Footer>
          <PoweredByFiit width="5rem" />
        </Footer>
      </Wrapper>
    </Container>
  );
};

export default Entry;
