declare global {
  interface Window {
    webkit?: {
      messageHandlers: {
        auxiliaryWindowLocation: {
          postMessage: (url: string) => void,
        },
      },
    },
    auxiliaryWindow?: {
      isConnected?: () => Promise<boolean>,
    },
  }
}

type SendWebkitMessageArgs = {
  url?: string,
};

export const useAuxiliaryWindow = () => {
  const sendWebkitMessage = ({ url = '' }: SendWebkitMessageArgs) => {
    if (!window.webkit?.messageHandlers) {
      return;
    }

    window.webkit.messageHandlers.auxiliaryWindowLocation.postMessage(url);
  };

  const isAuxiliaryWindowConnected: () => Promise<boolean> = async () => (
    window.auxiliaryWindow?.isConnected
      ? window.auxiliaryWindow?.isConnected()
      : Promise.resolve(false)
  );

  return { sendWebkitMessage, isAuxiliaryWindowConnected };
};

export default useAuxiliaryWindow;
