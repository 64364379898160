import React, { useState, useEffect, useCallback } from 'react';
import styled, { useTheme } from 'styled-components';
import { debounce } from 'lodash';
import { useIntl } from 'react-intl';

import ArrowDownLeft from 'app/ui/components/atoms/icons/arrow-down-left';
import ChevronRight from 'app/assets/chevron-right-icon.svg';
import PresentationIcon from 'app/assets/presentation-icon.svg';
import useAuxiliaryWindow from 'app/hooks/use-auxiliary-window';

type SlideshowProps = {
  slides: React.ReactNode[];
  isPresenterView: boolean;
  onEndWhiteboard?: () => void;
};

enum SlideshowMessageType {
  CHANGE_SLIDE,
  CLOSE_SLIDESHOW,
}

type SlideshowBroadcastMessage = {
  type: SlideshowMessageType;
  index: number;
};

const SlideshowWrapper = styled.div<{ isVisible: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: ${({ isVisible }) => (isVisible ? '0' : '-100%')};
  left: 0;
  overflow: hidden;
  z-index: 101;
  display: flex;
  flex-direction: column;
  transition: bottom 0.4s ease-in-out;
`;

const SlideContent = styled.div`
  flex-grow: 1;
  width: 100%;
`;

const Slide = styled.div`
  width: 100%;
  height: 100%;
`;

const NavigationStripe = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.veryGray};
  padding: ${({ theme }) => `${theme.spacing.small} ${theme.spacing.medium}`};
  z-index: 102;
`;

const ActionButton = styled.button`
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.medium};
  border-radius: ${({ theme }) => theme.spacing.xsmall};
  cursor: pointer;
  height: 2.5rem;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CloseButton = styled(ActionButton)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `2px solid ${theme.colors.white}`};
  gap: ${({ theme }) => theme.spacing.xxxsmall};
`;

const PresentButton = styled(ActionButton)`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  border: none;
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.small};
`;

const NavigationButton = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.focoltone};
  border: none;
`;

const Arrow = styled.span<{ isLeft?: boolean }>`
  color: ${({ theme }) => theme.colors.black};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;

  ${({ isLeft }) => isLeft ? `transform: rotate(-180deg);` : ''};
`;

const channel = new BroadcastChannel('slideshow_sync');

const { CHANGE_SLIDE, CLOSE_SLIDESHOW } = SlideshowMessageType;

const Slideshow: React.FC<SlideshowProps> = ({ slides, isPresenterView, onEndWhiteboard }) => {
  const theme = useTheme();
  const intl = useIntl();

  const { isAuxiliaryWindowConnected, sendWebkitMessage } = useAuxiliaryWindow();
  
  const [isAuxWindowConnected, setIsAuxWindowConnected] = useState(false);

  useEffect(() => {
    const checkConnected = async () => {
      const connected = await isAuxiliaryWindowConnected();
      setIsAuxWindowConnected(connected);
    };
    checkConnected();
  });

  const [currentIndex, setCurrentIndex] = useState(0);
  const [localVisible, setLocalVisible] = useState(false);

  const closeLabel = intl.formatMessage({ id: 'whiteboard.close' });
  const presentLabel = intl.formatMessage({ id: 'whiteboard.present' });
  const presentAgainLabel = intl.formatMessage({ id: 'whiteboard.presentAgain' });

  const [presentButtonLabel, setPresentButtonLabel] = useState(presentLabel);

  const handleMessageReceived = useCallback((message: MessageEvent<SlideshowBroadcastMessage>) => {
    if (message.data) {
      switch (message.data.type) {
        case CHANGE_SLIDE:
          setCurrentIndex(message.data.index);
          break;
        case CLOSE_SLIDESHOW:
          handleEndWhiteboard();
          break;
        default:
          break;
      }
    }
  }, []);

  const debounceChangeSlide = useCallback(debounce((index: number) => {
    channel.postMessage({ type: CHANGE_SLIDE, index });
  }, 300), []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const initialIndex = Number(params.get('slideIndex'));
    if (!isNaN(initialIndex)) {
      setCurrentIndex(initialIndex)
    };

    setLocalVisible(true);

    channel.addEventListener('message', handleMessageReceived);
    return () => channel.removeEventListener('message', handleMessageReceived);
  }, [handleMessageReceived]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        changeSlide(Math.max(0, currentIndex - 1));
      } else if (event.key === 'ArrowRight') {
        changeSlide(Math.min(slides.length - 1, currentIndex + 1));
      } else if (event.key === 'Escape') {
        handleEndWhiteboard();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentIndex]);

  const changeSlide = (newIndex: number) => {
    setCurrentIndex(newIndex);
    debounceChangeSlide(newIndex);
  };

  const closeSlideshow = () => {
    if (isAuxWindowConnected) {
      return sendWebkitMessage({});
    }
    channel.postMessage({ type: CLOSE_SLIDESHOW });
  };

  const handleEndWhiteboard = () => {
    setLocalVisible(false);
    closeSlideshow();

    setTimeout(() => {
      if (onEndWhiteboard) {
        onEndWhiteboard();
      }
    }, 400);
  };

  const handlePresentButtonClick = () => {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set('isPresenterView', 'false');
    newUrl.searchParams.set('isSlideshowOn', 'true');
    newUrl.searchParams.set('slideIndex', currentIndex.toString());

    setPresentButtonLabel(presentAgainLabel);

    if (isAuxWindowConnected) {
      return sendWebkitMessage({ url: newUrl.toString() });
    }

    window.open(newUrl.toString(), '_blank');
  };

  return (
    <SlideshowWrapper isVisible={localVisible}>
      <SlideContent>
        <Slide>{slides[currentIndex]}</Slide>
      </SlideContent>
      {isPresenterView && (
        <NavigationStripe>
          <ButtonsContainer>
            <CloseButton onClick={handleEndWhiteboard}><ArrowDownLeft color={theme.colors.white} />{closeLabel}</CloseButton>
            <PresentButton onClick={handlePresentButtonClick}>
              <img src={PresentationIcon} alt="Present now" />
              {presentButtonLabel}
            </PresentButton>
          </ButtonsContainer>
          <ButtonsContainer>
            <NavigationButton onClick={() => changeSlide(Math.max(0, currentIndex - 1))}>
              <Arrow isLeft={true}><img src={ChevronRight} alt="Previous" /></Arrow>
            </NavigationButton>
            <NavigationButton onClick={() => changeSlide(Math.min(slides.length - 1, currentIndex + 1))}>
              <Arrow><img src={ChevronRight} alt="Next" /></Arrow>
            </NavigationButton>
          </ButtonsContainer>
        </NavigationStripe>
      )}
    </SlideshowWrapper>
  );
};

export default Slideshow;
